import _defineProperty from "/work/mobile-spot/projects/mobigeo-sdk-pbc/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { DATA_TYPE_INFOS, DATA_TYPE_PMRS, DATA_TYPE_PARTICIPANTS } from './dataConfig'; // import {  } from 'src/pages/pagesKeys';

import { CATEGORIES_CLASSIFICATIONS } from 'src/core/query/Query';
export function isLogoFullWidth(pageKey) {
  switch (pageKey) {
    default:
      return true;
  }
}
export var EVENT_PAGE_SPEAKERS_AS_ACCORDION = false;
export var SINGLE_ITEM_DATATYPES = [DATA_TYPE_INFOS, DATA_TYPE_PMRS];
export var DEFAULT_IMAGE = _defineProperty({}, DATA_TYPE_PARTICIPANTS, 'files/project/misc/default-speaker-round.png'); // CATEGORY_SYSTEM || TOP_CATS

export var CATEGORIES_CLASSIFICATION = CATEGORIES_CLASSIFICATIONS.TOP_CATS;