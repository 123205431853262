var defaultConf = require('./config-default');

var NODE_BACKEND_DOMAIN = '';
var NODE_WS_BAKEND_DOMAIN = '';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'blockchaincongress-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_PATH: '/home/www/palexpo/blockchaincongress/blockchaincongress2020/blockchaincongress-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  // DEPLOY_PATH: '~/palexpo/blockchaincongress/blockchaincongress2020/blockchaincongress-dev.mobile-spot.com',
  DEPLOY_PATH: '/home/www/mobile-blockchain2020',
  GOOGLE_ANALYTICS_ID: 'UA-152561747-1',
  projectId: '',
  // FCM sender id
  appId: '',
  // pushwoosh appid
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  },
  LOGIN: {
    WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA/tgServices/BlockchainAppWs',
    forgottenPasswordURL: 'https://blockchain2020.site.calypso-event.net/testSDA/authentification/mot-de-passe-oublie.htm'
  },
  USER_DATA: {
    WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA/tgServices/BlockchainAppWs'
  },
  PARTICIPANTS: {
    WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA/tgServices/BlockchainAppWs'
  }
});